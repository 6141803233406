import axios from 'axios';

export const authoriseUser = (token:object) =>
{
    return axios.post('/authorise', token);
};

export const getProfile = (token: string | undefined) =>
{
    let requestBody = { headers: {authorization : 'Bearer ' + token} };
    return axios.get('/profile', requestBody);
};