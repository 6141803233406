import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CreateProductDTO } from '../../services/dto/product.dto';

type ProductProps =
{
  selectProduct: (prod:any) => void;
}

export default function Products({selectProduct: selectProduct}: ProductProps)
{
    //need to use useState in order to make the component re-render when products get changed (I think)
    const [products, setProducts] = useState<CreateProductDTO[] | null>(null);

    const navigate = useNavigate();

    //LOOK CLOSELY: handlePurchase CREATES a custom function for the specified product
    const handlePurchase = (prod: CreateProductDTO) => () => 
    {
      selectProduct(prod);
      navigate('/checkout');
    };

    //fetch the products from the server on loading page. NOTE: empty array second parameter is required here to prevent useEffect from calling in a loop.
    useEffect(() => 
    {
      axios.get('/products').then(remoteProducts =>
        {
          console.log(remoteProducts);
          const data = remoteProducts.data;
          const tempProducts: CreateProductDTO[] = [];
          data.forEach((product: any) => {
            let tempProduct: CreateProductDTO = {} as CreateProductDTO;
            tempProduct.id = product.id;
            tempProduct.name = product.name;
            tempProduct.desc = product.desc;
            tempProduct.price = product.price;
            tempProduct.img = product.img;
            tempProducts.push(tempProduct);
          });
          //setProducts(remoteProducts.data);
          setProducts(tempProducts);
        }
      );
    }, []);

    if(!products)
    {
      return (
        <div className='min-h-screen flex items-center justify-center bg-gray-100'>
          <img src={'/gifs/LargeLoading.gif'} alt={'loading'}/>
        </div>
      );
    }
    
    //return an array of products with the correct IDs, names, descriptions, and purchase handlers.
    return (
        <div className='hero min-h-screen bg-gray-100 grow'>
          <div className='hero-content flex-col text-center max-w-none w-5/6 xl:w-3/4 mt-40'>

            {/* Title */}
            <div className='text-4xl xl:text-5xl xl:m-8'>Purchase via online payment</div>

            {/* Products */}
            <div className='w-full flex flex-wrap justify-center'>
              {products.map((prod:CreateProductDTO) => (
              <div key={prod.id} onClick={handlePurchase(prod)} className='cursor-pointer p-3  from-zinc-100 to-zinc-300 hover:bg-gradient-to-b'>
                <img src={'/images/store/' + prod.img + '.jpg'} alt={prod.name} />
                <h2>{prod.name}</h2>
                <h3>{'$' + prod.price}</h3>
                <button type='button' className='btn btn-block' onClick={handlePurchase(prod)}>Purchase</button>
              </div>
              ))}
            </div>

          </div>
        </div>
    )
}