import React from 'react'

export default function Contact()
{
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  
    return (
      <div className='hero min-h-screen bg-gray-100'> {/* Full screen */}
        <div className='sm:h-1/2 min-h-[20rem] mt-16 p-4 w-full flex justify-center bg-gray-200'> {/* Main contact section full width */}
          <div className='justify-around text-center flex flex-col w-3/5 max-w-[60rem] h-full'> {/* Main contact section reduced width */}
            
            {/* Top text */}
            <div><h1 className='text-4xl sm:text-5xl lg:text-6xl sm:mb-8'>GET IN TOUCH</h1></div>

            {/* Main contact */}
            <div className='w-full flex justify-between h-40 sm:h-60'> 
              <div className='flex flex-col items-center justify-between w-1/3 h-2/3 sm:w-36 px-2'>
                <div className='h-24 flex justify-center align-center items-center'><a href='https://discord.gg/AYyrXrAWK6' target='_blank' rel='noopener noreferrer'><img alt='Discord' src='/images/logos/DiscordLogo.png' className='object-scale-down h-24 w-5/6 m-auto'/></a></div>
                <div><p className='text-xl sm:text-3xl lg:text-4xl'>Discord</p></div>
              </div>
              <div className='flex flex-col items-center justify-between w-1/3 h-2/3 sm:w-36 px-2'>
                  <div className='h-24 flex justify-center align-center items-center'><a href='https://www.youtube.com/@godlike_guru' target='_blank' rel='noopener noreferrer'><img alt='YouTube' src='/images/logos/YoutubeLogo.png' className='object-scale-down h-24 w-5/6 m-auto'/></a></div>
                  <div><p className='text-xl sm:text-3xl lg:text-4xl'>YouTube</p></div>
              </div>
              <div className='flex flex-col items-center justify-between w-1/3 h-2/3 sm:w-36 px-2'>
                <div className='h-24 flex justify-center align-center items-center'><img alt='Gmail' src='/images/logos/GmailLogo.png' className='object-scale-down h-24 w-5/6 m-auto'/></div>
                <div><p className='text-xl sm:text-3xl lg:text-4xl'>support<br></br>@godlikegames.com</p></div>
              </div>
            </div>

            {/* Other socials */}
            <div className='w-full flex flex-col items-center '>
              <div><h1 className='text-2xl mb-2 mt-12 sm:m-4'>Socials</h1></div>
              <div className='flex gap-1'>
                <a href='https://www.tiktok.com/@godlike_guru' target='_blank' rel='noopener noreferrer'><img alt='TikTok' src='/images/logos/TikTokLogo.png' className='w-10'/></a>
                <a href='https://www.instagram.com/godlike_guru' target='_blank' rel='noopener noreferrer'><img alt='Instagram' src='/images/logos/InstaLogo.png' className='w-10'/></a>
                <a href='https://www.patreon.com/godlike_guru' target='_blank' rel='noopener noreferrer'><img alt='Patreon' src='/images/logos/PatreonLogo.png' className='w-10'/></a>
                <a href='https://www.twitch.tv/godlike_sef' target='_blank' rel='noopener noreferrer'><img alt='SefTwitch' src='/images/logos/TwitchLogo.png' className='w-10'/></a>
                <a href='https://www.twitch.tv/godlike_mighty' target='_blank' rel='noopener noreferrer'><img alt='MightyTwitch' src='/images/logos/TwitchLogo.png' className='w-10'/></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
}