import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

//pages
import Home from './pages/Home'
import Login from './pages/login/Login';
import Signup from './pages/login/Signup';
import Contact from './pages/Contact'
import ForgotPassword from './pages/login/ForgotPassword';
import Wizardium from './pages/games/Wizardium';
import {Profile} from './pages/account/Profile';

//components
import Navbar from './components/Navbar';
import Products from './pages/store/Products';
import Checkout from './pages/store/Checkout';
import Footer from './components/Footer';

//other import
import {CreateProductDTO} from './services/dto/product.dto';

//Remote url
axios.defaults.baseURL = 'https://us-central1-vrbr-25262.cloudfunctions.net/api'

//create an interface for the login context
export interface loginContextInterface
{
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

//create a context for the login state and the function to set the login state
export const LoginContext = createContext<loginContextInterface>({
  loggedIn: false, 
  setLoggedIn: () => {},
});

export const App: React.FC = () =>
{

  //create a state variable for the currently selected product
  const [selectedProduct, setSelectedProduct] = useState<CreateProductDTO | null>(null);
  //create state variable for whether or not the user is logged in
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  //check if the user is logged in on page load
  useEffect(() =>
  {
    const token = Cookies.get('godlikegames_token');
    if(token) {
      setLoggedIn(true);
      console.log('logged in');
    }
  }, []);

  return (
    <LoginContext.Provider value={{loggedIn, setLoggedIn}}>
        <Router>
          <div className='flex flex-col min-h-screen font-body font-bold'>
            <Navbar/>
            <Routes>
              <Route path = '/' element={<Home/>} />
              <Route path = '/login' element={<Login/>} />
              <Route path = '/signup' element={<Signup/>} />
              <Route path = '/store' element={<Products selectProduct={setSelectedProduct}/>} />
              <Route path = '/checkout' element={<Checkout selectedProduct={selectedProduct}/>} />
              <Route path = '/contact' element={<Contact/>} />
              <Route path = '/profile' element={<Profile/>} />
              <Route path = '/forgotPassword' element={<ForgotPassword/>} />
              <Route path = '/wizardium' element={<Wizardium/>} />
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
            <Footer/>
          </div>
        </Router>
    </LoginContext.Provider>
  );
}