import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { LoginContext, loginContextInterface } from '../../App';

export default function Signup()
{
  const navigate = useNavigate();
  const {loggedIn, setLoggedIn} = useContext<loginContextInterface>(LoginContext);
  const [email, setEmail] = useState('');
  const [username, setusername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorUsername, setErrorUsername] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  if(loggedIn)
  {
    navigate('/profile');
  }
  
  const handleSubmit = (event: React.FormEvent) => {

    //prevent default behavior. Default behavior results in page reload and the submitted information being added to the url
    event.preventDefault();

    setLoading(true);
    
    //create object representing inputted user data
    const userData = 
    {
      email,
      username,
      password,
      confirmPassword
    };
    
    //post the data to the endpoint (hopefully this hits firebase express API)
    axios.post('/signup',userData)
    .then(res =>
      {
        setLoading(false);
        console.log(res);
        setErrorEmail('');
        setErrorUsername('');
        setErrorPassword('');
        setErrorConfirm('');
        setSuccess('Sign up successful. ');
      })
      .catch(err =>
        {
          setLoading(false);
          let error = err.response.data;
          console.log(error.email);
          console.log(error.username);
          console.log(error.password);
          setErrorEmail(error.email);
          setErrorUsername(error.username);
          setErrorPassword(error.password);
          setErrorConfirm(error.confirmPassword);
          console.log(errorEmail);
          console.log(errorPassword);
          console.log(errorUsername);
        }
      );
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    switch(event.target.name)
    {
      case('email'):
        setEmail(event.target.value);
        break;

      case('username'):
        setusername(event.target.value);
        break;

      case('password'):
        setPassword(event.target.value);
        break;

      case('confirmPassword'):
        setConfirmPassword(event.target.value);
        break;

      default:
        console.log('Unknown event type on log in page.')
    }
  };
  

  return (
    <div className='hero min-h-screen bg-gray-100'>
      <div className='flex flex-col items-center justify-center w-[22rem] p-8 gap-4 text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-md'>
        <h1 className='text-5xl'>Sign up</h1>
        {success && <div className='bg-green-400 rounded-md w-full'><p>{success}</p><Link className='underline' to='/login'>Please log in</Link></div>}
        <form noValidate onSubmit={handleSubmit} className='flex flex-col w-full'>
          <input type='email' placeholder='Email' name='email' className='input input-bordered w-full' onChange={handleChange}/> {/* Email input */}
          <div className='flex flex-start'><p className={errorEmail ? 'leading-none text-red-600 text-xs mb-1' : 'h-3'}>{errorEmail}</p></div>
          <input type='text' placeholder='Username' name='username' className='input input-bordered w-full' onChange={handleChange}/> {/* Username input */}
          <div className='flex flex-start'><p className={errorUsername ? 'leading-none text-red-600 text-xs mb-1' : 'h-3'}>{errorUsername}</p></div>
          <input type='password' placeholder='Password' name='password' className='input input-bordered w-full' onChange={handleChange}/> {/* Password input */}
          <div className='flex flex-start'><p className={errorPassword ? 'leading-none text-red-600 text-xs mb-1' : 'h-3'}>{errorPassword}</p></div>
          <input type='password' placeholder='Confirm Password' name='confirmPassword' className='input input-bordered w-full' onChange={handleChange}/> {/* Confirm input */}
          <div className='flex flex-start'><p className={errorConfirm ? 'leading-none text-red-600 text-xs mb-1' : 'h-3'}>{errorConfirm}</p></div>
          <button type='submit' className='btn btn-block btn-primary'>{!loading ? <div className='w-full'>Sign up</div> : <div className='w-full '><img src={'/gifs/Loading.gif'} alt={'loading'} className='m-auto'/></div>}</button>
        </form>
        <p>Already have an account? <Link className='underline' to='/login'>Log in</Link></p>
      </div>
    </div>
  );
}