import { Link } from 'react-router-dom';
import React from 'react';

export default function Footer() {
    return (
      <div>
        {/* footer */}
        <footer className='footer items-center p-4 bg-neutral text-neutral-content'>
            <div className='items-center grid-flow-col'>
              <Link to='/'><img alt='Logo' src='/images/logos/Logo.png' className='w-16'/></Link> 
              <p>Copyright © 2023 - All right reserved.</p>
            </div> 
            <div className='grid-flow-col gap-4 md:place-self-center md:justify-self-end hidden lg:grid'>
                {/* target='_blank' results in new tab opening and rel='noopener noreferrer' prevents security vulnerabilities */}
                <a href='https://discord.gg/9gTWJcW9Y2' target='_blank' rel='noopener noreferrer'><img alt='DiscordLogo' src='/images/logos/DiscordLogo.png' className='object-scale-down h-8 w-8 m-auto'/></a>
                <a href='https://www.youtube.com/@godlikegameslimited' target='_blank' rel='noopener noreferrer'><img alt='YouTubeLogo' src='/images/logos/YoutubeLogo.png' className='object-scale-down h-8 w-8 m-auto'/></a>
            </div>
        </footer>
      </div>
    )
  }