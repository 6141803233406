import React, { useEffect, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { ProfileDTO } from '../../services/dto/profile.dto';
import { LoginContext } from '../../App';
import { getProfile } from '../../services/user-services';

export const Profile: React.FC = () =>
{
    let loginContext = useContext(LoginContext);

    //should be an object of key-> value attributes of the signed in user
    let [profileData, setProfileData] = useState<ProfileDTO | null>(null);

    useEffect(() => {

        if(!loginContext.loggedIn){return;}

        async function fetchProfile()
        {
            try {
                //get the token from the cookie
                const token = Cookies.get('godlikegames_token');

                //get profile details from backend
                getProfile(token).then(profile =>
                    {
                        console.log(profile);
                        let profileDataNew = profile.data;

                        const tempProfileData: ProfileDTO = {} as ProfileDTO;
                        tempProfileData.Username = profileDataNew.username;
                        tempProfileData.Email = profileDataNew.email;
                        tempProfileData.Currency = profileDataNew.currency;

                        setProfileData(tempProfileData);
                        console.log(tempProfileData);
                    });
            }
            
            catch (error)
            {
                console.log(error);
            }
        }

        fetchProfile();
    }, [loginContext]);

    if(!loginContext.loggedIn)
    {
        return (
            <div className='hero min-h-screen bg-gray-100'>
                <div className='flex flex-col items-center justify-center max-w-md p-8 gap-4 text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-md'>
                    <h1>You're not logged in, or something went wrong.</h1>
                </div>
            </div>
        )
    }

    if(profileData != null && Object.keys(profileData).length != 0)
    {
        return (
            <div className='hero min-h-screen bg-gray-100'>
                <div className='flex flex-col items-center justify-center sm:w-3/4 max-w-5xl p-8 gap-8 m-4 mt-24 text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg bg-base-100'>

                    <h1 className='text-5xl w-full text-left py-8'>Profile</h1>

                    {/* Personal Information */}
                    <div className='w-full'>
                        <h1 className='text-3xl w-full text-left'>Personal Information</h1>
                        <div className='mx-auto grid grid-cols-2 w-full'> 
                            <p className='row-start-1 row-end-1 col-start-1 col-end-1 border-b-2 border-slate-100 text-left py-5'>Email</p><p className='row-start-1 row-end-1 col-start-2 col-end-2 border-b-2 border-slate-100 text-left py-5 break-words'>{profileData.Email}</p>
                            <p className='row-start-2 row-end-2 col-start-1 col-end-1 border-b-2 border-slate-100 text-left py-5'>Username</p><p className='row-start-2 row-end-2 col-start-2 col-end-2 border-b-2 border-slate-100 text-left py-5'>{profileData.Username}</p>
                            <p className='row-start-3 row-end-3 col-start-1 col-end-1 border-b-2 border-slate-100 text-left py-5'>Password</p><p className='row-start-3 row-end-3 col-start-2 col-end-2 border-b-2 border-slate-100 text-left py-5'><Link className='underline' to='/forgotpassword'>Reset password</Link></p>
                        </div>
                    </div>

                    {/* Purchases */}
                    <div className='w-full'>
                        <h1 className='text-3xl w-full text-left'>Purchases</h1>
                        <div className='mx-auto grid grid-cols-2 w-full'> 
                            <p className='row-start-3 row-end-3 col-start-1 col-end-1 border-b-2 border-slate-100 text-left py-5'>Godlike Gold</p><p className='row-start-3 row-end-3 col-start-2 col-end-2 border-b-2 border-slate-100 text-left py-5'>{profileData.Currency}</p>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
    else
    {
        return(
            <div className='min-h-screen flex items-center justify-center bg-gray-100'>
                <img src={'/gifs/LargeLoading.gif'} alt={'loading'}/>
            </div>
        )
    }
}