import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { LoginContext, loginContextInterface } from '../../App';

//functional component to be displayed on the login page. Handles submitting login information to the backend.
function Login()
{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const {loggedIn, setLoggedIn} = useContext<loginContextInterface>(LoginContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    if(loggedIn)
    {
      navigate('/profile');
    }

    const handleSubmit = (event: React.FormEvent) =>
    {
      //prevent default behavior. Default behavior results in page reload and the submitted information being added to the url
      event.preventDefault();

      setLoading(true);
      
      //create object representing inputted user data
      const userData = 
      {
        email,
        password
      };
      
      //post the data to the endpoint (hopefully this hits firebase express API)
      axios.post('/login',userData)
      .then(res =>
        {
          setLoading(false);
          setLoggedIn(true);
          Cookies.set('godlikegames_token', res.data.token, { expires: 1/24, path: '' });
          console.log('Logged in');
          console.log(res.data);
          navigate('/');
        })
        .catch(err =>
          {
            setLoading(false);
            setErrors('Sorry, the credentials you are using are invalid. Please try again.');
          }
        );
    };
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {
      switch(event.target.name)
      {
        case('email'):
          setEmail(event.target.value);
          break;

        case('password'):
          setPassword(event.target.value);
          break;

        default:
          console.log('Unknown event type on login page.')
      }
    };

    return (
      <div className='hero min-h-screen min-w-screen bg-gray-100'>
        <div className='flex flex-col items-center justify-center w-[22rem] m-2 p-8 gap-4 text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-md text'>
          <h1 className='text-5xl'>Log in</h1>
          <form noValidate onSubmit={handleSubmit} className='flex flex-col w-full gap-4'>
            {errors && <div className='bg-red-400 rounded-md'><p>{errors}</p><Link className='underline' to='/forgotpassword'>Forgot password?</Link></div>}
            <input type='email' placeholder='Email' name='email' className='input input-bordered w-full' onChange={handleChange}/>
            <div className='flex flex-col items-start'>
              <input type='password' placeholder='Password' name='password' className='input input-bordered w-full' onChange={handleChange}/>
              <Link className='underline' to='/forgotpassword'>Forgot password?</Link>
            </div>
            <button type='submit' className='btn btn-block btn-primary'>{!loading ? <div className='w-full'>Log in</div> : <div className='w-full '><img src={'/gifs/Loading.gif'} alt={'loading'} className='m-auto'/></div>}</button>
          </form>
          <p> Don't have an account? <Link className='underline' to='/signup'>Sign up</Link></p>
        </div>
      </div>
    );
}

Login.propTypes =
{
  name: PropTypes.string
}

export default Login;