import React from 'react';
import { useState } from 'react';
import axios from 'axios';

export default function ForgotPassword()
{
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState('');

    const handleSubmit = (event: React.FormEvent) =>
    {
        event.preventDefault();
        axios.post('/forgotPassword', {email})
        .then(res =>
            {
                setEmailSent(res.data.message);
            })
        .catch(err =>
            {
                alert('Error sending password reset email. Please try again.');
                setEmail('');
            });
    }

    return (
    <div className='hero min-h-screen bg-gray-100'>
      <div className='flex flex-col items-center justify-center w-full sm:w-[32rem] p-8 gap-4 text-center shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-md'>
        <h1 className='text-3xl'>Reset password</h1>
        {emailSent ? (
          <h3>The Email has been sent. Check your Inbox!</h3> 
        ) : (
          <div className='flex flex-col gap-4 w-full'>
            <input type='email' placeholder='Email' name='email' className='input input-bordered w-full' onChange={(e) => setEmail(e.target.value)} required/>
            <div><button type='submit' className='btn btn-primary w-40' onClick={handleSubmit}>Reset password</button></div>
          </div>
        )}
      </div>
    </div>
    );
}