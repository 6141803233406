import axios from 'axios';
import {useState, useEffect} from 'react';
import React from 'react';

export default function Wizardium()
{
    const [blobURL, setBlobURL] = useState<string | undefined>(undefined);
    
    useEffect(() =>
    {
      async function getBlob()
      {
        const response = await axios.get('/videos/wizardium', {responseType: 'blob'});
        console.log('response: ', response);
        setBlobURL(URL.createObjectURL(response.data));
      }
      getBlob();
    }, []);

    return (
      <div className='grow' >
        <div className='mx-auto grid grid-rows-1'>

          {/* Video */}
          { blobURL ? 
            <div className='row-start-1 row-end-2 col-start-1 col-end-2'>
              <video src={blobURL} autoPlay loop muted className='z-50 object-center object-cover m-auto h-screen w-screen pointer-events-none'/>
            </div>
            :
            <img alt='Initial video frame' src='/images/firstFrame.jpg' className='z-10 object-center object-cover m-auto h-screen w-screen pointer-events-none'/>
          }
            
          {/* body */}
          <div className='hero min-h-screen bg-gray-100'>
            <div className='hero-content text-center'>
              <div className='max-w-md'>
                <a href='https://godlikegames.itch.io/wizardium' target='_blank' rel='noopener noreferrer'>
                  <button type='button' className='text-white bg-gradient-to-r from-amber-400 to-amber-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-5xl px-5 py-2.5 text-center me-2 mb-2'>Play now!</button>
                </a>
                <p className='py-6 text-xl'>Fight your friends or other unknown sorcerers around the world in this immersive VR wizard fighting experience.</p>
                <p className='py-6 text-xl'>Check out how we made the game <a className='text-blue-600 underline' href='https://www.youtube.com/@godlike_guru' target='_blank' rel='noopener noreferrer'>here</a>.</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      
    );
}
