import * as React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { LoginContext } from '../App';

function Navbar() {
  //get the login state and the function to set the login state from the context
  const {loggedIn, setLoggedIn} = useContext(LoginContext);

  const logout = () =>
  {
    setLoggedIn(false);
    //remove the token cookie
    document.cookie='godlikegames_token=; expires=Sun, 20 Aug 2000 12:00:00 UTC';  
  }

  return (
    <div className='z-50 navbar flex fixed bg-gradient-to-b from-gray-100/30 via-gray-100/10 text-amber-500'>

      {/* LOGO and dropdown */}
      <div className='sm:pl-5 pr-2.5'>
        <div className='dropdown'>
          <label tabIndex={0} className='btn btn-ghost sm:hidden'>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'><path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h8m-8 6h16' /></svg>
          </label>
          <ul tabIndex={0} className='menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52'>
            <li><Link to='/'>Home</Link></li>
            <li>
              <a href='/wizardium'>Games</a>
              <ul className='p-2'>
                <li><Link to='/wizardium'>Wizardium</Link></li>
              </ul>
            </li>
            <li><Link to='/contact'>Contact</Link></li>
          </ul>
        </div>
        <Link to='/'><img alt='Logo' src='/images/logos/Logo.png' className='w-12'/></Link>
      </div>

      {/* Link buttons */}
      <div className='hidden sm:flex'>
        <ul className='menu menu-horizontal px-1'>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/wizardium'>Wizardium</Link></li>
          <li><Link to='/contact'>Contact</Link></li>
        </ul>
      </div>

      {/* Profile pic if logged in, else log in button */}
      <div className='justify-end grow flex content-center'>
        <ul className='menu menu-horizontal px-1 sm:mx-4 flex items-center'>
          <li><Link to='/store'>Store</Link></li>

          {loggedIn ? (
              <div className='dropdown dropdown-end'>
                <label tabIndex={0} className='btn btn-ghost btn-circle avatar'>
                  <div className='w-10 rounded-full'>
                    <img alt='WizardiumProfile' src='/images/WizardProfilePic.png' />
                  </div>
                </label>
                <ul tabIndex={0} className='menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52'>
                  <li>
                    <Link className='justify-between' to='/profile'>
                      Profile
                    </Link>
                  </li>
                  <li><Link to='/' onClick={logout}>Logout</Link></li>
                </ul>
              </div>
          )

          :

          (
            <li><Link to='/login'>Log in</Link></li>
          )}

        </ul>
      </div>
    </div>
  );
}

export default Navbar;
