import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CreateProductDTO } from '../services/dto/product.dto';

interface CheckoutFormProps
{
  selectedProduct: CreateProductDTO | null;
  clientSecret: string;
}

export default function CheckoutForm({selectedProduct, clientSecret}: CheckoutFormProps)
{
  //get a reference to the stripe and elements using hooks
  const stripe = useStripe(); //This might be accessing the stripePromise stored in the Elements component that wraps this component, but this is not necessarily the case - I dont' know.
  const elements = useElements(); //not sure what this does

  const [message, setMessage] = useState<String | undefined>(''); //the message returned from stripe
  const [isLoading, setIsLoading] = useState(false); //whether or not the payment is currently loading

  //function handler for checkout form submission
  const handleSubmit = async (event: React.FormEvent) =>
  {
    //stop form from auto-refreshing
    event.preventDefault();
    
    //check whether Stripe.js has loaded. Disable form submission if so.
    if(!stripe || !elements) {return;}

    setIsLoading(true);
    
    //confirm payment, I THINK through the data collected in the PaymentElement, passed somehow through the elements variable
    //automatically redirects to specified URL if payment completes successfully.
    const { error } = await stripe.confirmPayment(
      {
        elements,
        confirmParams: 
        {
          return_url: 'https://www.godlikegames.com/profile'
        }
      }
    );

    //check if there were any errors. This code will not be run if the payment is confirmed successfully, perhaps because the user is redirected to another URL?
    if(error.type === 'card_error' || error.type === 'validation_error') {setMessage(error.message);}

    else {setMessage('an unexpected error occurred'); console.log(error);}

    setIsLoading(false);
  };

  //return actual checkout form using stripe elements so we don't have to create them and worry about their information handling
  return (
    <div className='checkout-form'>
      <p className='mb-4'>Amount: ${selectedProduct?.price}</p>
      <form onSubmit={handleSubmit}>
        <PaymentElement id='payment-element'/>
        <button type='submit' className='btn btn-block btn-primary mt-4' disabled={isLoading || !stripe || !elements}>
          {isLoading ? <div className='w-full '><img alt='Loading...' src={'/gifs/Loading.gif'} className='m-auto'/></div> : <div className='w-full'>Pay now</div>}
        </button>
      </form>
    </div>
  );
}