import React, { useEffect, useState, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import { LoginContext } from '../../App';
import {CreateProductDTO} from '../../services/dto/product.dto';
import CheckoutForm from '../../components/CheckoutForm';

interface CheckoutProps
{
  selectedProduct: CreateProductDTO | null;
}

export default function Checkout({selectedProduct}: CheckoutProps)
{
  //create a stateful client secret
  const [clientSecret, setClientSecret] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');

  const {loggedIn} = useContext(LoginContext);

  useEffect(() =>
  {
    try
    {
      //note that setstate is asynchronous, so this won't be set straight away
      setCurrentEmail((jwt_decode(Cookies.get('godlikegames_token') as string) as any).email);
    }

    catch (error)
    {
      console.log(error);
    }
  }, [loggedIn]);

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    //create a PaymentIntent immediately upon loading the checkout, and receive the client secret from the server.
    if(currentEmail && selectedProduct)
    {
      axios.post('/stripe/charge', {productId: selectedProduct?.id, receipt_email: currentEmail}).then(data =>
      {
        //get the client secret that is associated to the payment intent that was just created
        setClientSecret(data.data.client_secret);
      });
    }
  }, [currentEmail]);

  //load stripe
  const stripePromise = loadStripe('pk_live_51MYixGBhpkEfaU70FjvsKl1sqDr9DqLmLkO2QrYUNq1GvKTc45n3MWC7wFqp1jeFhdd8fOPJo7vEg8ohNqpCespk000ti5gSLR');

  const options = 
  {
    clientSecret
  };

  if(!loggedIn)
  {
    return (
      <div className='min-h-screen flex items-center justify-center'>
        <h1>Oops! You're not signed in. Visit the Login page and do so in order to buy something!</h1>
      </div>
    );
  }

  //Show an error message if there is no client secret - believe this corresponds to there being no payment intent.
  if(!selectedProduct)
  {
    return (
      <div className='min-h-screen flex items-center justify-center'>
        <h1>Oops! You have no product selected! Navigate back to the products page and select one.</h1>
      </div>
    );
  } 

  else
  {
    //conditionally render checkout form on existence of client secret
    return (
      <div className='min-h-screen flex items-center justify-center mt-4'>
        {(
          clientSecret ? 
          (
            <div className='w-full sm:w-3/4 max-w-xl p-8 gap-8 m-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg bg-base-100'>
              <h1>Checkout: buying {selectedProduct?.name}</h1>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm selectedProduct={selectedProduct} clientSecret={clientSecret}/>  
              </Elements>
            </div>
          ) 
          : 
          (
            <img src={'/gifs/LargeLoading.gif'} alt={'loading'}/>
          )
        )}
      </div>
    );
  }
}