import React from 'react';

export default function Home()
{
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

  return (
    <div className='grow bg-black-200' >
      <div className='mx-auto grid grid-rows-1'>

        {/* Background image */}
        <div className='row-start-1 row-end-2 col-start-1 col-end-2 h-screen bg-gray-950'>
        </div>

        {/* Planet image */}
        <div className='row-start-1 row-end-2 col-start-1 col-end-2'>
          <div className='hero min-h-screen grow flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center justify-center p-8 gap-4 w-full md:w-[52rem] h-1/4'>
              <img alt='planet' src='/gifs/planet.gif' className='z-20 object-center object-cover m-auto w-full pointer-events-none'/> 
            </div>
          </div>
        </div>

        {/* Logo */}
        <div className='row-start-1 row-end-2 col-start-1 col-end-2'>
          <div className='hero min-h-screen grow flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center justify-center p-8 gap-4 w-full md:w-[48rem] h-1/4'>
              <img alt='logo' src='/images/logos/Logo.png' className='w-1/3 md:w-60 z-30'/>
              <img alt='logoText' src='/images/logos/LogoText.png' className='pb-10 z-30'/>
            </div>
          </div>
        </div>
        
        {/* body */}
        <div className='hero min-h-screen bg-gray-100 grow'>
          <div className='hero-content text-center'>
            <div className='max-w-xl'>
              <h1 className='text-4xl font-bold'>Meet the Godlike Gurus!</h1>
              <p className='text-xl py-6'>Brace yourself, because you've just stumbled upon the home of the Godlike Gurus. We make the best games and videos; you can watch and play them for free!</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}